import DataObjectField from "@/utilities/classes/data-object-field";

const buildWorkScopeSubmissionDataObject = (
  currentWorkScope,
  ContractorEstimate,
  ContractorEstimateName,
  AlternateContractorEstimate,
  AlternateContractorEstimateName,
  relatedLoan,
  generalContractor,
  GeneralContractorEstimate,
  GeneralContractorEstimateName,
  AlternateGeneralContractorEstimate,
  AlternateGeneralContractorEstimateName
) => {
  if (generalContractor.isLandlord == "Yes") {
    generalContractor.isLandlord = true;
  } else if (generalContractor.isLandlord == "No") {
    generalContractor.isLandlord = false;
  } else {
    generalContractor.isLandlord = null;
  }

  var locationSubmit = "";
  var typeSubmit = "";
  var fixturesSubmit = "";
  var workscopeSubmit = "";

  if (currentWorkScope.location != null) {
    locationSubmit = currentWorkScope.location.join("; ");
    console.log(locationSubmit);
  }

  if (currentWorkScope.type != null) {
    typeSubmit = currentWorkScope.type.join("; ");
    console.log(typeSubmit);
  }

  if (currentWorkScope.fixtures != null) {
    fixturesSubmit = currentWorkScope.fixtures.join("; ");
  }

  if (currentWorkScope.WorkScope != null) {
    workscopeSubmit = currentWorkScope.WorkScope.join("; ");
  }
  const dataObject = {
    qdbapi: {
      udata: "RIF_WORKSCOPE",
      apptoken: "d8phrhgbq4mmfwc3rkhnddciskd3",
      field: [
        new DataObjectField(currentWorkScope.id, 6),
        new DataObjectField(currentWorkScope.contractorName, 7),
        new DataObjectField(currentWorkScope.contractorLicenseNumber, 8),
        {
          _fid: "9",
          _filename: ContractorEstimateName,
          __text: ContractorEstimate
        },
        {
          _fid: "10",
          _filename: AlternateContractorEstimateName,
          __text: AlternateContractorEstimate
        },
        new DataObjectField(locationSubmit, 11),
        new DataObjectField(currentWorkScope.estimatedSize, 12),
        new DataObjectField(typeSubmit, 13),
        new DataObjectField(currentWorkScope.estimatedCost, 14),
        new DataObjectField(currentWorkScope.approximateLength, 15),
        new DataObjectField(currentWorkScope.description, 19),
        new DataObjectField(currentWorkScope.estimatedArea, 20),
        new DataObjectField(fixturesSubmit, 22),
        new DataObjectField(currentWorkScope.underfloorPlumbing, 23),
        new DataObjectField(currentWorkScope.estimatedCountRepaired, 24),
        new DataObjectField(currentWorkScope.estimatedCountReplaced, 61),
        new DataObjectField(currentWorkScope.treesRemoved, 18),
        new DataObjectField(currentWorkScope.repairReplace, 26),
        new DataObjectField(workscopeSubmit, 29),
        new DataObjectField(currentWorkScope.wiringWorkScope, 30),
        new DataObjectField(relatedLoan, 44),
        new DataObjectField(generalContractor.name, 32),
        new DataObjectField(generalContractor.licenseNumber, 33),
        {
          _fid: "34",
          _filename: GeneralContractorEstimateName,
          __text: GeneralContractorEstimate
        },
        new DataObjectField(generalContractor.generalContractorUsed, 35),
        new DataObjectField(generalContractor.isLandlord, 62),
        {
          _fid: "63",
          _filename: AlternateGeneralContractorEstimateName,
          __text: AlternateGeneralContractorEstimate
        },
        new DataObjectField(currentWorkScope.changeStatus, 95)
      ]
    }
  };

  return dataObject;
};

export default buildWorkScopeSubmissionDataObject;
