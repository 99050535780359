<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column">
        <div class="content">
          <ValidationObserver v-slot="{ errors }" tag="div">
            <input-form
              ref="form"
              :errors="errors"
              name="WorkscopeForm"
              @submit.prevent="handleFileUpload"
            >
              <br />
              <br />
              <h1 v-if="initResponse != null">
                Repair Change Request Form
              </h1>
              <h1 v-else>Work Scope Form</h1>
              <div v-if="initResponse != null" class="columns">
                <br />
                <div class="column">
                  <br />
                  <callout type="info">
                    <h4>
                      Complete this form to request approval to change the scope
                      of repair work to be completed with a Rental Improvement
                      Fund loan. This form should be completed in the following
                      instances:
                    </h4>
                    <ul>
                      <li>You want to change contractors</li>
                      <li>
                        You want to add, change, or remove something from the
                        repair scope that costs over $500
                      </li>
                      <li>
                        You have been instructed to complete the form following
                        a RIF inspection
                      </li>
                    </ul>
                    <b
                      >To get started, enter the seven letter verification code
                      that was sent to you when you created your RIF Landlord
                      Profile</b
                    >
                  </callout>
                </div>
              </div>
              <div
                v-if="lookupcode == null && initResponse != null"
                class="columns"
              >
                <br />
                <div class="column">
                  <vee-textbox
                    v-model="lookupCode"
                    error="This field is required for the status check"
                    name="input-lookup"
                    required
                    rules="required"
                    placeholder="Enter your verification code"
                    type="text"
                  />
                </div>
              </div>
              <div
                v-if="lookupcode == null && initResponse != null"
                class="actions columns"
              >
                <!--Required criteria for above div: v-if="(ProofOfDeliveryDocument.length != 0 && RentLedger.length != 0)"-->
                <div class="column is-2 is-offset-20">
                  <button
                    class="button is-primary"
                    :disabled="
                      SubmitStatus.Value === 'PENDING' ||
                        SubmitStatus.Value === 'OK'
                    "
                    @click="searchRecord()"
                  >
                    SEARCH
                  </button>
                </div>

                <div class="column">
                  <div
                    v-if="SubmitStatus.Value === SubmitStatus.Options.PENDING"
                  >
                    Submitting...
                  </div>
                  <div v-if="SubmitStatus.Value === SubmitStatus.Options.OK">
                    OK
                  </div>
                  <div v-if="SubmitStatus.Value === SubmitStatus.Options.ERROR">
                    Error
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column" v-html="workScopeSearch"></div>
              </div>
              <div
                v-if="
                  recordCheck != null &&
                    (lookupcode != null || initResponse == null)
                "
                class="columns"
              >
                <div class="column">
                  <label
                    ><b
                      >As a reminder, the scope of repairs that is currently
                      approved at the property covers:</b
                    ></label
                  >
                  <div v-html="approvedWorkScope"></div>
                </div>
              </div>
              <div
                v-if="
                  recordCheck == null &&
                    (lookupcode != null || initResponse == null)
                "
                class="columns"
              >
                <div class="column">
                  <vee-radio
                    v-model="GeneralContractor.selected"
                    :options="radioOptions.YesNo"
                    label="Is a single contractor doing all of the work?"
                    @input="onNoGeneralContractor()"
                  />
                </div>
              </div>
              <div
                v-if="
                  recordCheck != null &&
                    (lookupcode != null || initResponse == null)
                "
                class="columns"
              >
                <div class="column">
                  <label
                    ><b
                      >Which areas are you modifying (adding/changing/removing)
                      from your previous work scope?</b
                    ></label
                  >
                </div>
              </div>
              <div
                v-if="lookupcode != null || initResponse == null"
                class="columns"
              >
                <div class="column">
                  <vee-radio
                    v-model="ExteriorRepairs.selected"
                    :options="radioOptions.YesNo"
                    label="Exterior Repairs"
                    @input="onNoExteriorRepairs"
                  />
                </div>
                <div class="column">
                  <vee-radio
                    v-model="InteriorRepairs.selected"
                    :options="radioOptions.YesNo"
                    label="Interior Repairs"
                    @input="onNoInteriorRepairs"
                  />
                </div>
                <div class="column">
                  <vee-radio
                    v-model="BuildingSystems.selected"
                    :options="radioOptions.YesNo"
                    label="Building Systems (Mechanical/Electric/Plumbing/Fire Protection)"
                    @input="onNoBuildingSystems"
                  />
                </div>
                <div class="column">
                  <vee-radio
                    v-model="Health.selected"
                    :options="radioOptions.YesNo"
                    label="Health"
                    @input="onNoHealth"
                  />
                </div>
              </div>

              <div class="columns">
                <!--  -->
                <div v-if="ExteriorRepairs.selected === 'Yes'" class="column">
                  <checkbox
                    v-model="ExteriorRepairs.checkedOptions"
                    :label="
                      recordCheck != null
                        ? 'What exterior repairs are you modifying (adding/changing/removing)?'
                        : 'What are your exterior repairs?'
                    "
                    :options="WorkScopeSelection.ExteriorRepairs"
                    :text-key="textKey"
                    :value-key="valueKey"
                  />
                </div>
                <!--  -->
                <div v-if="InteriorRepairs.selected === 'Yes'" class="column">
                  <checkbox
                    v-model="InteriorRepairs.checkedOptions"
                    :label="
                      recordCheck != null
                        ? 'What interior repairs are you modifying (adding/changing/removing)?'
                        : 'What are your interior repairs?'
                    "
                    :options="WorkScopeSelection.InteriorRepairs"
                    :text-key="textKey"
                    :value-key="valueKey"
                  />
                </div>
                <!--  -->
                <div v-if="BuildingSystems.selected === 'Yes'" class="column">
                  <checkbox
                    v-model="BuildingSystems.checkedOptions"
                    :label="
                      recordCheck != null
                        ? 'What building systems are you modifying (adding/changing/removing)?'
                        : 'What are your building systems?'
                    "
                    :options="WorkScopeSelection.BuildingSystems"
                    :text-key="textKey"
                    :value-key="valueKey"
                  />
                </div>
                <div v-if="Health.selected === 'Yes'" class="column">
                  <checkbox
                    v-model="Health.checkedOptions"
                    :label="
                      recordCheck != null
                        ? 'What health repairs are you modifying (adding/changing/removing)?'
                        : 'What are your health repairs?'
                    "
                    :options="WorkScopeSelection.Health"
                    :text-key="textKey"
                    :value-key="valueKey"
                  />
                </div>
              </div>

              <div v-if="GeneralContractor.selected == 'Yes'" class="columns">
                <div class="column">
                  <vee-textbox
                    v-model="GeneralContractor.name"
                    :name="`input-general-contractor-name`"
                    required
                    error="This field is required"
                    rules="required"
                    placeholder="Contractor Name"
                    type="text"
                  />
                </div>
                <div class="column">
                  <vee-textbox
                    v-model="GeneralContractor.licenseNumber"
                    :name="`input-general-contractor-license-number`"
                    required
                    error="This field is required"
                    rules="required"
                    placeholder="Contractor License Number"
                    type="text"
                  />
                </div>
              </div>
              <div v-if="GeneralContractor.selected == 'Yes'" class="columns">
                <div class="column">
                  <vee-dropdown
                    v-model="GeneralContractor.isLandlord"
                    label="Are you or a family member a full or partial owner of this contractor business?"
                    :options="radioOptions.YesNo"
                    placeholder="--Select an Option--"
                    required
                    rules="required"
                    error="This field is required"
                  />
                </div>
              </div>
              <div
                class="columns"
                v-if="GeneralContractor.selected == 'Yes'"
                style="margin-left:1%"
              >
                <callout type="info"
                  >Submit complete documents with all pages in a single file. If
                  necessary, combine document pages into a single file using
                  <a href="https://jpg2pdf.com" target="_blank"
                    >https://jpg2pdf.com</a
                  >.</callout
                >
                <div class="column">
                  <h5>
                    Contractor Estimate: <b style="color:red">(required)</b>
                  </h5>
                  <ul id="upload-list-rent-ledger-documents">
                    <li
                      v-for="file in GeneralContractor.contractorEstimate"
                      :key="file.id"
                    >
                      <span>{{ file.name }} - </span>
                      <span v-if="file.error" class="is-love-park-red">
                        Something went wrong - {{ file.errorMessage }}
                      </span>

                      <span v-else-if="file.success" class="is-phanatic-green">
                        Upload Successful. Please select another document to
                        upload if you have multiple documents.
                      </span>
                      <span v-else-if="file.uploading" class="is-flyers-orange">
                        Uploading Document...</span
                      >
                    </li>
                  </ul>
                  <file-upload
                    ref="upload"
                    v-model="GeneralContractor.contractorEstimate"
                    :input-id="`file-upload-general-contractor-estimate`"
                    extensions="gif,jpg,jpeg,png,webp,pdf"
                    accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
                    :size="1024 * 1024 * 4.5"
                    @input-filter="inputFilter"
                  >
                    <button class="button is-primary">
                      <i class="fa fa-plus" />
                      Select ONE File to Upload
                    </button>
                  </file-upload>
                </div>
                <!-- <div class="column">
                  <h5>
                    Alternate Contractor Estimate:
                    <b style="color:red">(required)</b>
                  </h5>
                  <ul id="upload-list-rent-ledger-documents">
                    <li
                      v-for="file in GeneralContractor.alternateContractorEstimate"
                      :key="file.id"
                    >
                      <span>{{ file.name }} - </span>
                      <span v-if="file.error" class="is-love-park-red">
                        Something went wrong - {{ file.errorMessage }}
                      </span>

                      <span v-else-if="file.success" class="is-phanatic-green">
                        Upload Successful. Please select another document to
                        upload if you have multiple documents.
                      </span>
                      <span v-else-if="file.uploading" class="is-flyers-orange">
                        Uploading Document...</span
                      >
                    </li>
                  </ul>
                  <file-upload
                    ref="upload"
                    v-model="GeneralContractor.alternateContractorEstimate"
                    :input-id="
                      `file-upload-alternate-general-contractor-estimate`
                    "
                    extensions="gif,jpg,jpeg,png,webp,pdf"
                    accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
                    :size="1024 * 1024 * 4.5"
                    @input-filter="inputFilter"
                  >
                    <button class="button is-primary">
                      <i class="fa fa-plus" />
                      Select ONE File to Upload
                    </button>
                  </file-upload>
                </div> -->
              </div>
              <div
                v-for="repair in fullRepairList"
                :key="repair.id"
                :class="`entry-information-${repair.id}`"
              >
                <div v-if="fullRepairList.length > 0">
                  <div class="entry-container">
                    <label
                      ><b>{{ repair.id }}</b></label
                    >
                  </div>
                  <div v-if="recordCheck != null" class="columns">
                    <div class="column">
                      <vee-dropdown
                        v-model="repair.changeStatus"
                        label="Proposed Modification"
                        :options="Change_Status"
                        placeholder="Proposed Modification"
                        required
                        rules="required"
                        error="This field is required"
                      />
                    </div>
                    <div class="column"></div>
                    <div class="column"></div>
                    <div class="column"></div>
                    <div class="column"></div>
                  </div>

                  <div v-if="repair.changeStatus != 'Remove'" class="columns">
                    <div
                      v-if="
                        GeneralContractor.selected == 'No' ||
                          GeneralContractor.selected == null
                      "
                      class="column"
                    >
                      <vee-textbox
                        v-model="repair.contractorName"
                        :name="`input-contractor-name-${repair.id}`"
                        required
                        error="This field is required"
                        rules="required"
                        placeholder="Contractor Name"
                        type="text"
                      />
                    </div>
                    <div
                      v-if="
                        GeneralContractor.selected == 'No' ||
                          GeneralContractor.selected == null
                      "
                      class="column"
                    >
                      <vee-textbox
                        v-model="repair.contractorLicenseNumber"
                        :name="`input-contractor-license-number-${repair.id}`"
                        required
                        error="This field is required"
                        rules="required"
                        placeholder="Contractor License Number"
                        type="text"
                      />
                    </div>
                    <div class="column col-md-6">
                      <vee-textbox
                        v-model="repair.estimatedCost"
                        :name="`input-estimated-cost-${repair.id}`"
                        required
                        error="This field is required"
                        rules="required|doubleCurrency"
                        placeholder="Estimated Cost"
                        type="number"
                      />
                    </div>
                  </div>
                  <div
                    v-if="repair.changeStatus != 'Remove'"
                    class="columns justify-content-center"
                  >
                    <div
                      v-if="
                        repair.id == 'Roof/Gutters/Chimney' ||
                          repair.id == 'Masonry/Siding' ||
                          repair.id == 'Sidewalk & Exterior Stairs' ||
                          repair.id == 'Flooring' ||
                          repair.id == 'Painting'
                      "
                      class="column col-md-6"
                    >
                      <vee-textbox
                        v-model="repair.estimatedSize"
                        :name="`input-contractor-estimate-sqft-${repair.id}`"
                        required
                        error="This field is required"
                        rules="required|numeric"
                        placeholder="Estimated Size SQFT"
                        type="number"
                      />
                    </div>
                    <div v-if="repair.id == 'Fence/Gate'" class="column">
                      <vee-textbox
                        v-model="repair.approximateLength"
                        :name="`input-approximate-length-sqft-${repair.id}`"
                        required
                        error="This field is required"
                        rules="required|numeric"
                        placeholder="Approximate Length FT"
                        type="number"
                      />
                    </div>
                    <div
                      v-if="
                        repair.id == 'Roof/Gutters/Chimney' ||
                          repair.id == 'Fence/Gate'
                      "
                      class="column"
                    >
                      <vee-checkbox
                        v-model="repair.location"
                        label="Select location"
                        numOfColumns="2"
                        :input-id="`repair-location-${repair.id}`"
                        :options="
                          (repair.id == 'Roof/Gutters/Chimney'
                            ? Roof_Gutters_Chimney_Location
                            : null) ||
                            (repair.id == 'Fence/Gate'
                              ? Fence_Gate_Location
                              : null)
                        "
                        rules="required"
                        required
                        placeholder="Select Location"
                        mode="eager"
                      />
                    </div>
                    <div v-if="repair.id == 'Tree Removal'" class="column">
                      <vee-textbox
                        v-model="repair.treesRemoved"
                        :name="`input-trees-removed-${repair.id}`"
                        required
                        error="This field is required"
                        rules="required|numeric"
                        placeholder="Estimated Trees Removed"
                        type="number"
                      />
                    </div>
                    <div
                      v-if="
                        repair.id == 'Exterior Doors' ||
                          repair.id == 'Interior Doors' ||
                          repair.id == 'Windows' ||
                          repair.id == 'Bathroom' ||
                          repair.id == 'Kitchen' ||
                          repair.id == 'Laundry'
                      "
                      class="column"
                    >
                      <vee-textbox
                        v-model="repair.estimatedCountRepaired"
                        :name="`input-estimated-count-repaired-${repair.id}`"
                        rules="numeric"
                        placeholder="Estimated Count Repaired"
                        type="number"
                      />
                    </div>
                    <div
                      v-if="
                        repair.id == 'Exterior Doors' ||
                          repair.id == 'Interior Doors' ||
                          repair.id == 'Windows' ||
                          repair.id == 'Bathroom' ||
                          repair.id == 'Kitchen' ||
                          repair.id == 'Laundry'
                      "
                      class="column"
                    >
                      <vee-textbox
                        v-model="repair.estimatedCountReplaced"
                        :name="`input-estimated-count-replaced-${repair.id}`"
                        rules="numeric"
                        placeholder="Estimated Count Replaced"
                        type="number"
                      />
                    </div>
                    <div
                      v-if="
                        repair.id == 'Porch/Deck' ||
                          repair.id == 'Adaptive Modifications'
                      "
                      class="column"
                    >
                      <vee-textbox
                        v-model="repair.description"
                        :name="`input-description-${repair.id}`"
                        required
                        error="This field is required"
                        rules="required"
                        placeholder="Description"
                        type="text"
                      />
                    </div>
                    <div v-if="repair.id == 'Bathroom'" class="column">
                      <vee-dropdown
                        v-model="repair.underfloorPlumbing"
                        label="Underfloor Plumbing"
                        :options="radioOptions.YesNo"
                        placeholder="Underfloor Plumbing"
                        required
                        rules="required"
                        error="This field is required"
                      />
                    </div>
                    <div v-if="repair.id == 'Bathroom'" class="column">
                      <vee-checkbox
                        v-model="repair.fixtures"
                        label="Select Bathroom Fixtures"
                        numOfColumns="2"
                        :input-id="`repair-fixtures-${repair.id}`"
                        :options="Bathroom_Fixtures"
                        rules="required"
                        required
                        placeholder="Select Bathroom Fixtures"
                        mode="eager"
                      />
                    </div>
                    <div
                      v-if="
                        repair.id == 'Masonry/Siding' ||
                          repair.id == 'Flooring' ||
                          repair.id == 'Stairs/railing' ||
                          repair.id == 'Pest Control' ||
                          repair.id == 'Kitchen' ||
                          repair.id == 'Fire Protection'
                      "
                      class="column"
                    >
                      <vee-checkbox
                        v-model="repair.type"
                        label="Select Type"
                        :numOfColumns="repair.id == 'Stairs/railing' ? 8 : 2"
                        :input-id="`repair-type-${repair.id}`"
                        :options="
                          (repair.id == 'Masonry/Siding'
                            ? Masonry_Siding_Type
                            : null) ||
                            (repair.id == 'Flooring' ? Flooring_Type : null) ||
                            (repair.id == 'Stairs/railing'
                              ? Stairs_railing_Type
                              : null) ||
                            (repair.id == 'Pest Control' ? Pest_Type : null) ||
                            (repair.id == 'Kitchen' ? Kitchen_Type : null) ||
                            (repair.id == 'Fire Protection' ? Fire_Type : null)
                        "
                        rules="required"
                        required
                        placeholder="Select Type"
                        mode="eager"
                      />
                    </div>
                  </div>
                  <div
                    v-if="
                      (repair.id == 'Water Heater' ||
                        repair.id == 'Main Drain' ||
                        repair.id == 'HVAC' ||
                        repair.id == 'Electrical') &&
                        repair.changeStatus != 'Remove'
                    "
                    class="columns"
                  >
                    <div
                      v-if="
                        repair.id == 'Water Heater' ||
                          repair.id == 'Main Drain' ||
                          repair.id == 'HVAC'
                      "
                      class="column"
                    >
                      <vee-dropdown
                        v-model="repair.repairReplace"
                        label="Repair or Replace"
                        :options="Repair_Replace"
                        placeholder="Repair or Replace"
                        required
                        rules="required"
                        error="This field is required"
                      />
                    </div>
                    <div v-if="repair.id == 'Electrical'" class="column">
                      <vee-dropdown
                        v-model="repair.wiringWorkScope"
                        label="Wiring Work Scope"
                        :options="Wiring_Workscope"
                        placeholder="Wiring Work Scope"
                        required
                        rules="required"
                        error="This field is required"
                      />
                    </div>
                  </div>
                  <div v-if="repair.changeStatus != 'Remove'" class="columns">
                    <div
                      v-if="repair.id == 'Electrical' || repair.id == 'HVAC'"
                      class="column"
                    >
                      <vee-checkbox
                        v-model="repair.WorkScope"
                        label="Select Work Scope"
                        numOfColumns="2"
                        :input-id="`repair-workscope-${repair.id}`"
                        :options="
                          (repair.id == 'Electrical'
                            ? Electrical_WorkScope
                            : null) ||
                            (repair.id == 'HVAC' ? HVAC_WorkScope : null)
                        "
                        rules="required"
                        required
                        placeholder="Select Work Scope"
                        mode="eager"
                      />
                    </div>
                  </div>
                  <div v-if="repair.changeStatus != 'Remove'" class="columns">
                    <div
                      v-if="
                        GeneralContractor.selected == 'No' ||
                          GeneralContractor.selected == null
                      "
                      class="column"
                      style="margin-left:0.8%"
                    >
                      <callout type="info"
                        >Submit complete documents with all pages in a single
                        file. If necessary, combine document pages into a single
                        file using
                        <a href="https://jpg2pdf.com" target="_blank"
                          >https://jpg2pdf.com</a
                        >.</callout
                      >
                      <h5>
                        Contractor Estimate: <b style="color:red">(required)</b>
                      </h5>
                      <ul id="upload-list-rent-ledger-documents">
                        <li
                          v-for="file in repair.contractorEstimate"
                          :key="file.id"
                        >
                          <span>{{ file.name }} - </span>
                          <span v-if="file.error" class="is-love-park-red">
                            Something went wrong - {{ file.errorMessage }}
                          </span>

                          <span
                            v-else-if="file.success"
                            class="is-phanatic-green"
                          >
                            Upload Successful. Please select another document to
                            upload if you have multiple documents.
                          </span>
                          <span
                            v-else-if="file.uploading"
                            class="is-flyers-orange"
                          >
                            Uploading Document...</span
                          >
                        </li>
                      </ul>
                      <file-upload
                        ref="upload"
                        v-model="repair.contractorEstimate"
                        :input-id="
                          `file-upload-contractor-estimate-${repair.id}`
                        "
                        extensions="gif,jpg,jpeg,png,webp,pdf"
                        accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
                        :size="1024 * 1024 * 4.5"
                        @input-filter="inputFilter"
                      >
                        <button class="button is-primary">
                          <i class="fa fa-plus" />
                          Select ONE File to Upload
                        </button>
                      </file-upload>
                    </div>
                    <!-- <div
                      v-if="
                        repair.estimatedCost > 10000 &&
                          (GeneralContractor.selected == 'No' ||
                            GeneralContractor.selected == null)
                      "
                      class="column"
                    >
                      <h5>
                        Alternate Contractor Estimate:
                        <b style="color:red">(required)</b>
                      </h5>
                      <ul id="upload-list-rent-ledger-documents">
                        <li
                          v-for="file in repair.alternateContractorEstimate"
                          :key="file.id"
                        >
                          <span>{{ file.name }} - </span>
                          <span v-if="file.error" class="is-love-park-red">
                            Something went wrong - {{ file.errorMessage }}
                          </span>

                          <span
                            v-else-if="file.success"
                            class="is-phanatic-green"
                          >
                            Upload Successful. Please select another document to
                            upload if you have multiple documents.
                          </span>
                          <span
                            v-else-if="file.uploading"
                            class="is-flyers-orange"
                          >
                            Uploading Document...</span
                          >
                        </li>
                      </ul>
                      <file-upload
                        ref="upload"
                        v-model="repair.alternateContractorEstimate"
                        :input-id="
                          `file-upload-alternate-contractor-estimate-${repair.id}`
                        "
                        extensions="gif,jpg,jpeg,png,webp,pdf"
                        accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
                        :size="1024 * 1024 * 4.5"
                        @input-filter="inputFilter"
                        @input="handelAltEstimate"
                      >
                        <button class="button is-primary">
                          <i class="fa fa-plus" />
                          Select ONE File to Upload
                        </button>
                      </file-upload>
                    </div> -->
                  </div>
                </div>
                <!-- <div class="columns">
                  <div class="column">
                    <vee-dropdown
                      v-model="repair.changeStatus"
                      label="Change Status"
                      :options="Change_Status"
                      placeholder="Change Status"
                      required
                      rules="required"
                      error="This field is required"
                    />
                  </div>
                </div> -->
                <hr style="background-color:black;" />
                <br />
              </div>
              <div>
                <callout
                  type="danger"
                  v-if="
                    FilesReady == false &&
                      missingFilesError != '' &&
                      (GeneralContractor.selected == 'No' ||
                        GeneralContractor.selected == null)
                  "
                >
                  <b>You are missing the following Contractor Estimates:</b>
                  {{ missingFilesError }}
                </callout>
                <callout
                  type="danger"
                  v-if="
                    FilesReady == false &&
                      (missingFilesError != '' ||
                        GeneralContractor.contractorEstimate.length <= 0) &&
                      GeneralContractor.selected == 'Yes'
                  "
                >
                  <b>You are missing General Contractor Estimate.</b>
                </callout>
                <callout v-if="estimatedTotal > 0" type="info"
                  >Total estimated cost of all repairs on this form:
                  <b>${{ estimatedTotal.toLocaleString() }}</b>
                  <p>
                    Please confirm this total is correct before clicking Finish
                  </p></callout
                >
              </div>
              <br />
              <!-- <div>
                <callout
                  type="danger"
                  v-if="
                    FilesReady == false &&
                      missingAltFilesError != '' &&
                      (GeneralContractor.selected == 'No' ||
                        GeneralContractor.selected == null)
                  "
                >
                  <b
                    >You are missing the following Alternate Contractor
                    Estimates:</b
                  >
                  {{ missingAltFilesError }}
                </callout>
                <callout
                  type="danger"
                  v-if="
                    FilesReady == false &&
                      (missingAltFilesError != '' ||
                        GeneralContractor.alternateContractorEstimate.length <=
                          0) &&
                      GeneralContractor.selected == 'Yes'
                  "
                >
                  <b>You are missing Alternate General Contractor Estimate.</b>
                </callout>
              </div> -->
              <div class="actions columns">
                <!--Required criteria for above div: v-if="(ProofOfDeliveryDocument.length != 0 && RentLedger.length != 0)"-->
                <div class="column is-2 is-offset-20" style="margin-top:2%">
                  <button
                    class="button is-primary"
                    :disabled="
                      SubmitStatus.Value === 'PENDING' ||
                        SubmitStatus.Value === 'OK' ||
                        FilesReady === false
                    "
                    @click="handleFileUpload()"
                  >
                    FINISH
                  </button>
                </div>
                <div class="column">
                  <div
                    v-if="SubmitStatus.Value === SubmitStatus.Options.PENDING"
                  >
                    Submitting...
                  </div>
                  <div v-if="SubmitStatus.Value === SubmitStatus.Options.OK">
                    OK
                  </div>
                  <div v-if="SubmitStatus.Value === SubmitStatus.Options.ERROR">
                    Error
                  </div>
                </div>
              </div>
            </input-form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FileUpload from "vue-upload-component";
import options from "@/utilities/options";
import WorkScope from "@/utilities/classes/Workscope";
import {
  dataObjectToXml,
  resizeImage,
  getBase64,
  getPropertyValueOrDefault
} from "@/utilities/functions";
import buildWorkScopeSubmissionDataObject from "@/utilities/workscope-submission-object";
import buildWorkscopeInfoObject from "@/utilities/get-workscope-info-object";
import buildWorkScopeInfoInitObject from "@/utilities/get-workscope-object";
//import Multiselect from 'vue-multiselect';
//import { watch } from "fs";

//import buildLandlordDataFetchObject from "@/utilities/data-objects/landlord-data-fetch-object";

export default {
  name: "Work Scope",
  components: { FileUpload },
  mixins: [options],
  props: {
    loanId: { default: null, type: String },
    lookupcode: { default: null, type: String }
  },
  data() {
    return {
      //locationOptions: ['Main', 'Porch', 'Rear'],
      textKey: "text",
      valueKey: "value",
      workScopeSearch: null,
      lookupCode: this.lookupcode != "" ? this.lookupcode : null,
      recordCheck: null,
      initResponse: null,
      approvedWorkScope: null,
      FilesReady: false,
      missingFilesError: "",
      missingAltFilesError: "",
      estimatedTotal: null,
      GeneralContractor: {
        selected: null,
        name: null,
        licenseNumber: null,
        contractorEstimate: [],
        alternateContractorEstimate: [],
        isLandlord: null,
        generalContractorUsed: false
      },
      fullRepairList: [],
      ExteriorRepairs: {
        selected: null,
        checkedOptions: []
        // RoofGuttersChimneyCheck: null,
        // MasonrySidingCheck: null,
        // FenceGateCheck: null,
        // WindowsCheck: null,
        // ExteriorDoorsCheck: null,
        // SidewalkAndExteriorStairsCheck: null,
        // TreeRemovalCheck: null,
        // PorchDeckCheck: null
      },
      InteriorRepairs: {
        selected: null,
        checkedOptions: []
      },
      BuildingSystems: {
        selected: null,
        checkedOptions: []
      },
      Health: {
        selected: null,
        checkedOptions: []
      }
    };
  },
  watch: {
    selectedRepairs: {
      handler(newValue) {
        this.updateRepairList(newValue);
      },
      deep: true
    }
  },

  created() {
    this.checkForFiles();
    var vData = this;
    var dataObject = buildWorkScopeInfoInitObject(
      vData.loanId,
      "RIF_GET_WORKSCOPE_INIT"
    );
    var requestObj = {
      table: "bt6ip94f4",
      data: dataObject
    };
    axios
      .post(
        "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Rental-Improvement-Fund-Quickbase-Request-Proxy-Reads",
        requestObj
      )
      .then(response => {
        const responseObject = getPropertyValueOrDefault(
          ["data", "record", 0, "f"],
          response
        );
        vData.initResponse = responseObject;
        console.log(responseObject);
        if (responseObject != null && vData.lookupcode != null) {
          dataObject = buildWorkscopeInfoObject(
            vData.loanId,
            vData.lookupcode,
            "RIF_GET_WORKSCOPE"
          );
          requestObj = {
            table: "bt6ip94f4",
            data: dataObject
          };
          axios
            .post(
              "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Rental-Improvement-Fund-Quickbase-Request-Proxy-Reads",
              requestObj
            )
            .then(response => {
              const responseObject = getPropertyValueOrDefault(
                ["data", "record", 0, "f"],
                response
              );
              vData.recordCheck = getPropertyValueOrDefault(
                ["_text"],
                responseObject.find(i => i._attributes.id === "3")
              );
              vData.approvedWorkScope = getPropertyValueOrDefault(
                ["_text"],
                responseObject.find(i => i._attributes.id === "92")
              );
            });
        }
      });
  },
  computed: {
    selectedRepairs: function() {
      var exterior = this.ExteriorRepairs.checkedOptions;
      var interior = this.InteriorRepairs.checkedOptions;
      var buildingSystems = this.BuildingSystems.checkedOptions;
      var health = this.Health.checkedOptions;
      var exteriorAndInterior = exterior.concat(interior);
      var buildingSystemsAndHealth = buildingSystems.concat(health);
      return exteriorAndInterior.concat(buildingSystemsAndHealth);
    }
  },
  methods: {
    checkForFiles() {
      setInterval(() => {
        this.filesReady();
      }, 1000);
    },
    filesReady() {
      var fileFound = true;

      if (this.fullRepairList.length <= 0) {
        fileFound = false;
      }

      this.fullRepairList.forEach(function(e) {
        if (e.changeStatus == "Remove") {
          e.WorkScope = [];
          e.alternateContractorEstimate = [];
          e.approximateLength = null;
          e.contractorEstimate = [];
          e.contractorLicenseNumber = null;
          e.contractorName = null;
          e.description = null;
          e.estimatedArea = null;
          e.estimatedCost = null;
          e.estimatedCountRepaired = null;
          e.estimatedCountReplaced = null;
          e.estimatedSize = null;
          e.fixtures = [];
          e.location = [];
          e.repairReplace = null;
          e.repairTypes = [];
          e.treesRemoved = null;
          e.type = null;
          e.underfloorPlumbing = null;
          e.wiringWorkScope = null;
        }
      });

      let totalEstimates = this.fullRepairList.map(e => e.estimatedCost);

      this.estimatedTotal = 0;

      totalEstimates.forEach(
        e => (this.estimatedTotal = Number(e) + this.estimatedTotal)
      );

      var missingFiles = this.fullRepairList.filter(function(e) {
        return (
          (e.contractorEstimate == null || e.contractorEstimate.length <= 0) &&
          e.changeStatus != "Remove"
        );
      });

      if (
        this.GeneralContractor.selected == "No" ||
        this.GeneralContractor.selected == null
      ) {
        this.missingFilesError = "";

        for (var i = 0; i < missingFiles.length; i++) {
          if (i + 1 == missingFiles.length) {
            this.missingFilesError += missingFiles[i].id;
          } else {
            this.missingFilesError += missingFiles[i].id + ", ";
          }
        }
      }

      // var missingAltFiles = this.fullRepairList.filter(function(e) {
      //   return (
      //     e.estimatedCost > 10000 &&
      //     (e.alternateContractorEstimate == null ||
      //       e.alternateContractorEstimate.length <= 0) &&
      //     e.changeStatus != "Remove"
      //   );
      // });

      if (
        this.GeneralContractor.selected == "No" ||
        this.GeneralContractor.selected == null
      ) {
        this.missingAltFilesError = "";

        // for (var j = 0; j < missingAltFiles.length; j++) {
        //   if (j + 1 == missingAltFiles.length) {
        //     this.missingAltFilesError += missingAltFiles[j].id;
        //   } else {
        //     this.missingAltFilesError += missingAltFiles[j].id + ", ";
        //   }
        // }
      }

      if (missingFiles.length > 0) {
        fileFound = false;
      }

      if (
        this.GeneralContractor.selected == "Yes" &&
        this.GeneralContractor.contractorEstimate.length > 0 &&
        this.fullRepairList.length > 0
      ) {
        fileFound = true;
      }

      this.FilesReady = fileFound;
    },
    onNoExteriorRepairs() {
      if (this.ExteriorRepairs.selected === "No") {
        this.ExteriorRepairs.checkedOptions = [];
        this.updateRepairList();
      }
    },
    onNoInteriorRepairs() {
      if (this.InteriorRepairs.selected === "No") {
        this.InteriorRepairs.checkedOptions = [];
        this.updateRepairList();
      }
    },
    onNoBuildingSystems() {
      if (this.BuildingSystems.selected === "No") {
        this.BuildingSystems.checkedOptions = [];
        this.updateRepairList();
      }
    },
    onNoHealth() {
      if (this.Health.selected === "No") {
        this.Health.checkedOptions = [];
        this.updateRepairList();
      }
    },
    onNoGeneralContractor() {
      if (this.GeneralContractor.selected == "No") {
        this.GeneralContractor.name = null;
        this.GeneralContractor.licenseNumber = null;
        this.GeneralContractor.contractorEstimate = [];
        this.GeneralContractor.generalContractorUsed = false;
      }
    },
    handelAltEstimate(files) {
      console.log("added", files);
    },

    updateRepairList() {
      for (let i = 0; i < this.selectedRepairs.length; i++) {
        const repairId = this.selectedRepairs[i];
        const existingRepair = this.fullRepairList.find(
          repair => repair.id === repairId
        );
        if (existingRepair) {
          // If the repair already exists, update its properties if needed
          // existingRepair.property = value;
        } else {
          // If the repair doesn't exist, create a new one and add it to the list
          const workScopeObj = new WorkScope(i + 1);
          workScopeObj.id = repairId;
          this.fullRepairList.push(workScopeObj);
        }
      }
      this.fullRepairList = this.fullRepairList.filter(repair =>
        this.selectedRepairs.includes(repair.id)
      );
    },
    // updateRepairList() {
    //   this.fullRepairList = [];
    //   for (let i = 0; i < this.selectedRepairs.length; i++) {
    //     const workScopeObj = new WorkScope(i + 1);
    //     workScopeObj.id = this.selectedRepairs[i];
    //     this.fullRepairList.push(workScopeObj);
    //   }
    // },
    onNo() {
      if (this.ExteriorRepairs.selected == "No") {
        this.ExteriorRepairs.checkedOptions = [];
        this.updateRepairList();
      }
      if (this.InteriorRepairs.selected == "No") {
        this.InteriorRepairs.checkedOptions = [];
      }
      if (this.BuildingSystems.selected == "No") {
        this.BuildingSystems.checkedOptions = [];
      }
      if (this.Health.selected == "No") {
        this.Health.checkedOptions = [];
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?|exe?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    },
    searchRecord() {
      var vData = this;
      vData.workScopeSearch = "";
      const dataObject = buildWorkscopeInfoObject(
        vData.loanId,
        vData.lookupCode,
        "RIF_GET_WORKSCOPE"
      );
      const requestObj = {
        table: "bt6ip94f4",
        data: dataObject
      };
      axios
        .post(
          "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Rental-Improvement-Fund-Quickbase-Request-Proxy-Reads",
          requestObj
        )
        .then(response => {
          const responseObject = getPropertyValueOrDefault(
            ["data", "record", 0, "f"],
            response
          );
          vData.recordCheck = getPropertyValueOrDefault(
            ["_text"],
            responseObject.find(i => i._attributes.id === "3")
          );
          vData.approvedWorkScope = getPropertyValueOrDefault(
            ["_text"],
            responseObject.find(i => i._attributes.id === "92")
          );
          vData.lookupcode = vData.lookupCode;
        })
        .catch(error => {
          console.log(error);
          this.workScopeSearch =
            "<p>No Record Found. Check your lookup code.</p>";
        });
    },
    async getFile(uploadData) {
      if (uploadData.length) {
        let FileIdx = uploadData.length - 1;
        var fileObj = uploadData[FileIdx];
        fileObj.uploading = true;
        fileObj.error = false;
        fileObj.errorMessage = "";
        let file = fileObj.file;
        if (fileObj.type.match(/image.*/)) {
          file = await resizeImage({ file: file, maxSize: 1920 });
        }
        if (file.size >= 1024 * 1024 * 4.5) {
          fileObj.error = true;
          fileObj.errorMessage =
            "File is too large, please reduce your file's size and try again.";
        } else {
          return await getBase64(file);
        }
      }
    },
    async handleFileUpload() {
      var vData = this;
      vData.SubmitStatus.Value = vData.SubmitStatus.Options.PENDING;
      // var uploadData = vData.FileEntry.fileEntryList;
      // var entryIdx = vData.FileEntry.NumberOfFileEntriesSelected;
      const isInputValid = await vData.$refs.form.$parent.validate();
      try {
        if (isInputValid) {
          if (this.fullRepairList.length) {
            for (var i = 0; i < this.fullRepairList.length; i++) {
              var currentWorkScope = this.fullRepairList[i];

              if (this.GeneralContractor.selected == "Yes") {
                currentWorkScope.contractorName = this.GeneralContractor.name;
                currentWorkScope.contractorLicenseNumber = this.GeneralContractor.licenseNumber;
              }

              var ContractorEstimate = [];
              var ContractorEstimateName = "";

              if (
                (this.GeneralContractor.selected == "No" ||
                  this.GeneralContractor.selected == null) &&
                currentWorkScope.changeStatus != "Remove"
              ) {
                ContractorEstimate = await this.getFile(
                  this.fullRepairList[i].contractorEstimate
                );
                ContractorEstimateName = this.fullRepairList[i]
                  .contractorEstimate[0].name;
              }

              var AlternateContractorEstimate = [];
              var AlternateContractorEstimateName = "";

              // if (
              //   this.fullRepairList[i].alternateContractorEstimate != null &&
              //   currentWorkScope.changeStatus != "Remove"
              // ) {
              //   AlternateContractorEstimate = await this.getFile(
              //     this.fullRepairList[i].alternateContractorEstimate
              //   );
              //   AlternateContractorEstimateName = this.fullRepairList[i]
              //     .alternateContractorEstimate[0].name;
              // }

              var GeneralContractorEstimate = [];
              var GeneralContractorEstimateName = "";
              var AlternateGeneralContractorEstimate = [];
              var AlternateGeneralContractorEstimateName = "";

              if (this.GeneralContractor.selected == "Yes") {
                this.GeneralContractor.generalContractorUsed = true;
                GeneralContractorEstimate = await this.getFile(
                  this.GeneralContractor.contractorEstimate
                );
                GeneralContractorEstimateName = this.GeneralContractor
                  .contractorEstimate[0].name;

                // AlternateGeneralContractorEstimate = await this.getFile(
                //   this.GeneralContractor.alternateContractorEstimate
                // );
                // AlternateGeneralContractorEstimateName = this.GeneralContractor
                //   .alternateContractorEstimate[0].name;
              }

              const dataObject = buildWorkScopeSubmissionDataObject(
                currentWorkScope,
                ContractorEstimate,
                ContractorEstimateName,
                AlternateContractorEstimate,
                AlternateContractorEstimateName,
                vData.loanId,
                vData.GeneralContractor,
                GeneralContractorEstimate,
                GeneralContractorEstimateName,
                AlternateGeneralContractorEstimate,
                AlternateGeneralContractorEstimateName
              );

              const xmlDataObject = dataObjectToXml(dataObject);
              const requestObj = {
                table: "bt6ip94f4",
                action: "API_AddRecord",
                data: xmlDataObject
              };

              axios
                .post(
                  "https://djql8jp3sb.execute-api.us-east-1.amazonaws.com/Rental-Improvement-Fund-QuickBase-Request-Proxy",
                  requestObj
                )
                .then(result => {
                  //alert("THEN");
                  console.log(result);
                  this.fullRepairList[
                    i
                  ].contractorEstimate[0].uploading = false;
                  this.fullRepairList[i].contractorEstimate[0].success = true;
                  // if (
                  //   this.fullRepairList[i].alternateContractorEstimate.length >
                  //   0
                  // ) {
                  //   this.fullRepairList[
                  //     i
                  //   ].alternateContractorEstimate[0].uploading = false;
                  //   this.fullRepairList[
                  //     i
                  //   ].alternateContractorEstimate[0].success = true;
                  // }
                })
                .catch(error => {
                  console.log(error);
                  this.fullRepairList[i].contractorEstimate[0].error = true;
                  this.fullRepairList[i].contractorEstimate[0].errorMessage =
                    "File is too large, please reduce your file's size and try again.";
                  // if (
                  //   this.fullRepairList[i].alternateContractorEstimate.length >
                  //   0
                  // ) {
                  //   this.fullRepairList[
                  //     i
                  //   ].alternateContractorEstimate[0].error = true;
                  //   this.fullRepairList[
                  //     i
                  //   ].alternateContractorEstimate[0].errorMessage =
                  //     "File is too large, please reduce your file's size and try again.";
                  // }
                });
            }
          }
          vData.SubmitStatus.Value = vData.SubmitStatus.Options.OK;
          alert("Work Scope Submitted");
          vData.$router.push({ name: "WorkScopeSuccess" });
        } else {
          vData.SubmitStatus.Value = vData.SubmitStatus.Options.ERROR;
        }
      } catch (error) {
        console.log(error);
        vData.SubmitStatus.Value = vData.SubmitStatus.Options.ERROR;
      }
      //alert("Workscope Submitted");
      //window.location.reload();
    }
  }
};
</script>

<style>
/* Media Queries */

/* Mobile View */
@media screen and (max-width: 767px) {
  .columns {
    flex-direction: column;
  }

  .column {
    width: 100%;
    margin-bottom: 20px;
  }

  /* .column vee-checkbox .vee-checkbox-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 10px;
  } */

  .entry-container {
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

/* Tablet View */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .columns {
    flex-direction: column;
  }

  .column {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 20px;
  }

  .column.col-md-6 {
    width: 100%;
  }

  .entry-container {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 20px;
    text-align: center;
  }
}

/* Desktop View */
@media screen and (min-width: 1025px) {
  .columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .column {
    width: 50%;
    padding: 0 10px;
    margin-bottom: 20px;
  }

  .column.col-md-6 {
    width: 50%;
  }

  .entry-container {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 20px;
    text-align: center;
  }
}
</style>
